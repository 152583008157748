
import { defineComponent, ref, onMounted, reactive} from "vue";
import VisualizarProdutosConsultor from "./components/VisualizarProdutosConsultor.vue";
import VisualizarVendasProduto from "./components/VisualizarVendasProduto.vue";
import useEmitter from "@/composables/Emmiter";
import  {getIntegracaoDmsConcessionaria} from '@/services/IntegracaoDms'
import moment from 'moment';
import { ContentLoader } from 'vue-content-loader';
import useAlert from "@/composables/Alert";

export default defineComponent({
    name: "integracao-dms",

    components: {
    VisualizarProdutosConsultor,
    VisualizarVendasProduto,
    ContentLoader,
},
    
    setup() {
        const emitter = useEmitter();

        const dataAtual = moment();
        const mes = dataAtual.month()+1;
        const ano = dataAtual.format('YYYY');

        const dataDisplay = ref(dataAtual.locale("pt-br").format('MMMM - YYYY'));
        const mesExtenso = dataDisplay.value.charAt(0).toUpperCase() + dataDisplay.value.slice(1);
        dataDisplay.value = mesExtenso;

        const selectConcessionaria = ref(localStorage.getItem("filtroConcessionaria"));
        const codConcessionaria = selectConcessionaria.value;
        const dadosConcessionaria = ref();

        const vendaTotalConsultor = ref([]);
        const vendaTotalProdutosArray = ref([]);

        const produtosConsultor = ref ();

        const produtosCodProduto = ref ();

        const animate = ref(true);   
        
        const { showTimeAlert } = useAlert();

     
        // Venda Total de Produtos por CodConsultor
        function vendaTotalProdutosConsultor(dadosConcessionaria) { 
            const objetoOrdenado = dadosConcessionaria.sort((a, b) => a.CodConsultor.localeCompare(b.CodConsultor))
            const objetoReduzido = objetoOrdenado.reduce((resultado, objeto) => {
                const chave = objeto.CodConsultor;

                if (!resultado[chave]) {
                resultado[chave] = { ...objeto };
                } else {
                resultado[chave].Qtd = (parseFloat(resultado[chave].Qtd) + parseFloat(objeto.Qtd));
                }

                return resultado;
            }, {});

            
            return objetoReduzido;
        }
        
        // Venda Total de Produtos por CodProduto
        function vendaTotalProdutos(dadosConcessionaria) {
            const objetoOrdenado = dadosConcessionaria.sort((a, b) => a.CodProduto.localeCompare(b.CodProduto));
            const objetoReduzido = objetoOrdenado.reduce((resultado, objeto) => {
                const chave = objeto.CodProduto;

                if (!resultado[chave]) {
                resultado[chave] = { ...objeto };
                } else {
                resultado[chave].Qtd = (parseFloat(resultado[chave].Qtd) + parseFloat(objeto.Qtd));
                }

                return resultado;
            }, {});

            return objetoReduzido;
        }

       // Códigos do Produto com sua Quantidade vendida por consultor específico
        function consultorEspecifico(CodConsultor){
            produtosConsultor.value = dadosConcessionaria.value.filter(produto => produto.CodConsultor == CodConsultor)
        }

        // Códigos do Consultor com sua Quantidade vendida por produto específico   
        function produtoEspecifico(CodProduto){
            produtosCodProduto.value = dadosConcessionaria.value.filter(produto => produto.CodProduto == CodProduto)
        }

        function ordenarListaPaginada(){
            const virarArray =  Object.values(vendaTotalProdutosArray.value).reverse();
            vendaTotalProdutosArray.value = virarArray;
            trataInfo();
        }

        function ordenarLista(){
            const virarArray =  Object.values(vendaTotalConsultor.value).reverse();
            vendaTotalConsultor.value = virarArray;
        }

        const data = reactive({
            current_page: 1,
            total: 0,
            to: 0,
            last_page: 0,
            per_page: 50,
            data: [],
        });

        function trataInfo(page = 1, filter = false) {
            let objetoReduzido = [];

            objetoReduzido = Object.values(vendaTotalProdutosArray.value);

            data.total = objetoReduzido.length;
            data.last_page =
                Math.ceil(objetoReduzido.length / data.per_page) ?? 0;

            data.current_page = page;
            const start = data.per_page * (page - 1);
            const end = data.per_page * page;

            if (data.last_page == page) {
                data.to = objetoReduzido.length;
            } else if (end > objetoReduzido.length) {
                data.to = objetoReduzido.length;
            } else {
                data.to = end;
            }
            data.data = objetoReduzido.slice(start, end);
            
        }


        onMounted(async() => {
            try {
                dadosConcessionaria.value = await getIntegracaoDmsConcessionaria(codConcessionaria,mes,ano);
            } catch (error) {
                console.error(error);
                showTimeAlert("Não foi possível completar a solicitação", "error");   
                animate.value = false
            }
            

            //Para caso não tenha informação na data selecionada, retornar array vazio
            if (dadosConcessionaria.value.length > 0) {
                vendaTotalConsultor.value = vendaTotalProdutosConsultor(dadosConcessionaria.value);
                vendaTotalProdutosArray.value = vendaTotalProdutos(dadosConcessionaria.value);
                trataInfo();
            }else {
                vendaTotalConsultor.value = [];
                vendaTotalProdutosArray.value = [];
            }
            
            animate.value = false

        });


        emitter.on("filter-integracao-dms",async(data) => {
            
            animate.value = true
            try {
                const mesValue = moment(data.mes).month() + 1;
                const anoValue = moment(data.ano).year();
                dadosConcessionaria.value = await getIntegracaoDmsConcessionaria(data.codConcessionaria,mesValue,anoValue);
                const formatDate = moment(data.mes).locale("pt-br").format('MMMM');
                const mesExtenso = formatDate.charAt(0).toUpperCase() + formatDate.slice(1);
                dataDisplay.value = `${mesExtenso} - ${anoValue}`;
            } catch (error) {
                console.error(error);
                showTimeAlert("Não foi possível completar a solicitação", "error");   
                animate.value = false
            }

            //Para caso não tenha informação na data selecionada, retornar array vazio
            if (dadosConcessionaria.value.length > 0) {
                vendaTotalConsultor.value = vendaTotalProdutosConsultor(dadosConcessionaria.value);
                vendaTotalProdutosArray.value = vendaTotalProdutos(dadosConcessionaria.value);
            }else {
                vendaTotalConsultor.value = [];
                vendaTotalProdutosArray.value = [];
            }
            trataInfo();


            animate.value = false


        })




        return {
            vendaTotalConsultor,
            vendaTotalProdutosArray,
            consultorEspecifico,
            produtosConsultor,
            produtoEspecifico,
            produtosCodProduto,
            animate,
            dataDisplay,
            trataInfo,
            data,
            ordenarListaPaginada,
            ordenarLista,
        }

    },
})

